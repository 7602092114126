<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionCart">
      <p class="cartTitle">产品预售购物车</p>
      <div class="cartSteps">
        <el-steps :active="activeStep" finish-status="success">
          <el-step title="确认采购"></el-step>
          <el-step title="查看购物车"></el-step>
          <el-step title="生成协议"></el-step>
          <el-step title="填写订货卡下单"></el-step>
          <el-step title="等待排产"></el-step>
        </el-steps>
      </div>
      <div class="cartList">
        <div class="item">
          <el-table :data="tableData" border>
            <el-table-column prop="name" label="品种"></el-table-column>
            <el-table-column prop="quantity" label="数量"></el-table-column>
            <el-table-column prop="price" label="商品单价"></el-table-column>
            <el-table-column prop="jhDate" label="交货期"></el-table-column>
            <el-table-column prop="fee" label="手续费"></el-table-column>
            <el-table-column prop="lastPayDay" label="付剩余款期限"></el-table-column>
            <el-table-column prop="tradeUnitName" label="交易单位名称"></el-table-column>
            <el-table-column prop="createTime" label="创建日期" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime | DateFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="185">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="showDialog(scope.row)">生成协议</el-button>
                <el-button size="mini" @click="cancelOrder(scope.row)">撤单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
      <!-- 选择支付方式弹窗 -->
      <el-dialog
      v-dialogDrag
        class="payMethodDialog"
        title="付款方式"
        :close-on-click-modal="false"
        :visible.sync="payDialogVisible"
        width="400px"
        :before-close="handleClose"
      >
        <div class="payMethod">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-form-item label="请选择该商品的付款比例：" prop="payType">
              <el-select v-model="form.payType" placeholder="请选择" @change="calePayMoney()">
                <el-option
                  v-for="item in payTypeOptions"
                  :key="item.payId"
                  :label="item.payRate"
                  :value="item.payId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款金额">
              <span>{{ payMoney }}</span>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="payDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="comfirmOrder()">确认协议并支付首笔款</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import searchHeader from "../base/header/searchHeader";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      activeStep: 1,
      tableData: [
        // {
        //   name: "热轧直发卷",
        //   typeName: "Q235B",
        //   quantity: "30.00",
        //   jhDate: "2019/08/31",
        //   fee: "46200",
        //   lastPayDay: "1",
        //   tradeUnitName: "吨",
        //   createTime: "2019/07/12 14:45:07"
        // }
      ],
      payDialogVisible: false,
      payTypeOptions: [
        // {
        //   payId: 1,
        //   payRate: 0.2
        // },
        // {
        //   payId: 2,
        //   payRate: 0.6
        // }
      ],
      buyOrderId: null,
      buyOrderFunds: null,
      form: {
        payType: null
      },
      rules: {
        payType: [
          { required: true, message: "请选择商品的付款比例", trigger: "change" }
        ]
      },
      payMoney: null
    };
  },
  components: {
    searchHeader
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryShoppingList.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryShoppingList.param.page = this.current - 1;
      protocolFwd.param_queryShoppingList.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryShoppingList).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calePayMoney() {
      let payRate = null;
      for (let i = 0; i < this.payTypeOptions.length; i++) {
        if (this.form.payType == this.payTypeOptions[i].payId) {
          payRate = this.payTypeOptions[i].payRate;
        }
      }
      this.payMoney = Number(util.floatMul(this.buyOrderFunds, payRate));
    },
    showDialog(item) {
      this.payDialogVisible = true;
      this.buyOrderId = item.buyOrderId;
      this.buyOrderFunds = item.funds;
      protocolFwd.param_selectCommodityId.param.commodityId = item.commodityId;
      http.postFront(protocolFwd.param_selectCommodityId).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.payTypeOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleClose() {
      this.form.payType = null;
      this.buyOrderId = null;
      this.buyOrderFunds = null;
      this.payMoney = null;
      this.payDialogVisible = false;
      this.$refs["form"].resetFields();
    },
    comfirmOrder() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_payFirstOrder.param.payType = this.form.payType;
          protocolFwd.param_payFirstOrder.param.buyOrderId = this.buyOrderId;
          http.postFront(protocolFwd.param_payFirstOrder).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.payDialogVisible = false;
              this.$confirm("首款支付成功，请前往买家中心查看", "支付成功", {
                distinguishCancelAndClose: true,
                confirmButtonText: "去看看",
                cancelButtonText: "取消"
              })
                .then(() => {
                  this.$router.push("/matching/buyAgreeList");
                })
                .catch(action => {});
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
      // 演示用
      // this.payDialogVisible = false;
      // this.$alert("支付成功", "首款支付成功，请前往买家中心查看", {
      //   confirmButtonText: "去看看",
      //   callback: action => {
      //     this.$router.push("/matching/buyAgreeList");
      //   }
      // });
    },
    cancelOrder(item) {
      this.$confirm("请确认是否撤单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoBuyOrderTwo.param.buyOrderId = item.buyOrderId;
          http.postFront(protocolFwd.param_undoBuyOrderTwo).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.getDatas();
              this.$EL_MESSAGE(message);
            } else {
              this.$EL_MESSAGE(message);
            }
          });
          // 演示用
          // this.$EL_MESSAGE("撤单成功");
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.productionCart {
  padding: 15px;
  width: 80%;
  min-width: 1300px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.productionCart .cartTitle {
  font-size: 16px;
  font-weight: 700;
}
.productionCart .cartSteps {
  margin: 50px 100px;
}
.cartList .item {
  margin-bottom: 30px;
}
.payMethodDialog .payMethodTitle {
  margin-bottom: 10px;
  font-weight: 700;
}
</style>


